var render = function render(){
  var _vm$$appConfig, _vm$$appConfig$custom, _vm$$appConfig$custom2, _vm$offerConfig, _vm$offerConfig$featu, _vm$offerConfig2, _vm$offerConfig2$feat;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-register scrollbar"
  }, [_vm.badOffer ? _c('v-container', {
    staticClass: "ma-0 pa-0 menuBackground",
    attrs: {
      "fill-height": "",
      "fluid": "",
      "no-gutters": ""
    }
  }, [_c('v-row', {
    staticClass: "no-gutters d-flex flex-grow-1 flex-column align-center align-self-start"
  }, [_c('v-img', {
    attrs: {
      "src": require("../../assets/logo.png"),
      "alt": "logo",
      "height": "200px",
      "max-width": "350px",
      "contain": ""
    }
  }), _c('div', {
    staticClass: "white--text text-center display-2 font-weight-bold mt-6 mb-12"
  }, [_vm._v(" " + _vm._s(_vm.badeOfferMessage) + " ")]), _c('v-btn', {
    staticClass: "white--text text-none mt-12",
    attrs: {
      "large": "",
      "color": "orange"
    },
    on: {
      "click": _vm.goToLogin
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-left ")]), _vm._v(" " + _vm._s(_vm.$t("backLogin")) + " ")], 1)], 1)], 1) : _vm.offerConfig && _vm.offerConfig.subscription ? _c('v-container', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "align-stretches fill-height",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "menuBackground white--text",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('I18nSelector'), _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-img', {
    staticClass: "my-4",
    attrs: {
      "lazy-src": _vm.$appConfig.customization.login.logoFull,
      "max-height": "240",
      "max-width": "200",
      "src": _vm.$appConfig.customization.login.logoFull,
      "alt": "logo"
    }
  })], 1), ((_vm$$appConfig = _vm.$appConfig) === null || _vm$$appConfig === void 0 ? void 0 : (_vm$$appConfig$custom = _vm$$appConfig.customization) === null || _vm$$appConfig$custom === void 0 ? void 0 : (_vm$$appConfig$custom2 = _vm$$appConfig$custom.login) === null || _vm$$appConfig$custom2 === void 0 ? void 0 : _vm$$appConfig$custom2._id) !== 'DEFAULT' ? [_c('h1', {
    staticClass: "text-center white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('yourAccount', {
    version: _vm.offerConfig.title[_vm.$userLocale] || _vm.offerConfig.title['en']
  })) + " ")])] : [_c('h1', {
    staticClass: "text-center white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('yourVersion', {
    version: _vm.offerConfig.title[_vm.$userLocale] || _vm.offerConfig.title['en']
  })) + " ")]), _vm.trialPeriod ? [_c('div', {
    staticClass: "text-center white--text mt-3"
  }, [_c('span', {
    staticClass: "display-1 font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.trialPeriod) + " ")]), _c('span', {
    staticClass: "text-h5 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$tc('trialPeriod', _vm.trialPeriod)) + " ")])]), _c('div', {
    staticClass: "text-center white--text mt-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('andThen')) + " ")])] : _vm._e(), _c('div', {
    staticClass: "text-center white--text mt-3 pb-5"
  }, [_c('span', {
    staticClass: "display-1 font-weight-bold",
    class: {
      'text-decoration-line-through': _vm.discounts.length > 0
    }
  }, [_vm.offerConfig.subscription.price === 0 ? [_vm._v(" " + _vm._s(_vm.$t("free")))] : [_vm._v(_vm._s(_vm.offerConfig.subscription.price) + " € ")]], 2), _vm.discounts.length > 0 ? _c('span', {
    staticClass: "display-1 font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.discounts[0].reducedOfferPrice) + " € ")]) : _vm._e(), _vm.offerConfig.subscription.price !== 0 && _vm.offerConfig.subscription.recurrence === 'YEARLY' ? _c('span', [_vm._v(_vm._s(_vm.$t('VATExcluded')) + " / " + _vm._s(_vm.$tc('year')))]) : _vm.offerConfig.subscription.price !== 0 ? _c('span', [_vm._v(_vm._s(_vm.$t('VATExcluded')) + " / " + _vm._s(_vm.$tc('month')))]) : _vm._e(), _vm.discounts.length > 0 ? _c('span', {
    staticClass: "caption font-weight-bold orange--text"
  }, [_vm._v(" (-" + _vm._s(_vm.discounts[0].discount) + "% ")]) : _vm._e(), _vm.discounts.length > 0 && _vm.discounts[0].duration > 0 ? _c('span', {
    staticClass: "caption"
  }, [_vm._v(" " + _vm._s(_vm.$t('for')) + " ")]) : _vm._e(), _vm.discounts.length > 0 && _vm.discounts[0].duration > 0 ? _c('span', {
    staticClass: "caption font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.discounts[0].duration) + " "), _vm.offerConfig.subscription.recurrence === 'YEARLY' ? [_vm._v(_vm._s(_vm.$tc('year', _vm.discounts[0].duration)) + ")")] : [_vm._v(_vm._s(_vm.$tc('month', _vm.discounts[0].duration)) + ")")]], 2) : _vm._e(), _vm.discounts.length > 0 && _vm.discounts[0].duration === -1 ? _c('span', {
    staticClass: "caption font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('lifetime')) + ") ")]) : _vm._e(), _vm._l(_vm.discounts, function (discount, index) {
    return _c('div', {
      key: index,
      staticClass: "text-center"
    }, [_vm.discounts.length > 0 ? [index > 0 && _vm.showFullList ? _c('span', {
      staticClass: "caption white--text"
    }, [_vm._v(" " + _vm._s(_vm.$t('then')) + " ")]) : _vm._e(), index > 0 && _vm.showFullList ? _c('span', {
      staticClass: "caption font-weight-bold orange--text"
    }, [_vm._v(" " + _vm._s(discount.reducedOfferPrice) + " € ")]) : _vm._e(), index > 0 && _vm.showFullList ? _c('span', {
      staticClass: "caption white--text"
    }, [_vm._v(" " + _vm._s(_vm.$t('VATExcluded')) + " / "), _vm.offerConfig.subscription.recurrence === 'YEARLY' ? [_vm._v(_vm._s(_vm.$tc('year')))] : [_vm._v(_vm._s(_vm.$tc('month')))]], 2) : _vm._e(), index > 0 && _vm.showFullList ? _c('span', {
      staticClass: "caption font-weight-bold orange--text"
    }, [_vm._v(" (-" + _vm._s(discount.discount) + "% ")]) : _vm._e(), index > 0 && _vm.showFullList && discount.duration > 0 ? _c('span', {
      staticClass: "caption white--text"
    }, [_vm._v(" " + _vm._s(_vm.$t('for')) + " ")]) : _vm._e(), index > 0 && _vm.showFullList && discount.duration === -1 ? _c('span', {
      staticClass: "caption font-weight-bold orange--text"
    }, [_vm._v(" " + _vm._s(_vm.$t('lifetime')) + ") ")]) : _vm._e(), index > 0 && _vm.showFullList && discount.duration > 0 ? _c('span', {
      staticClass: "caption font-weight-bold orange--text"
    }, [_vm._v(" " + _vm._s(discount.duration) + " "), _vm.offerConfig.subscription.recurrence === 'YEARLY' ? [_vm._v(_vm._s(_vm.$tc('year', discount.duration)) + ")")] : [_vm._v(_vm._s(_vm.$tc('month', discount.duration)) + ")")]], 2) : _vm._e()] : _vm._e()], 2);
  }), _vm.discounts.length > 1 ? _c('div', {
    staticClass: "text-center"
  }, [_c('span', [_c('v-btn', {
    staticClass: "dashed-button mt-1",
    attrs: {
      "color": "white",
      "outlined": "",
      "rounded": "",
      "x-small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showFullList = !_vm.showFullList;
      }
    }
  }, [!_vm.showFullList ? _c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-chevron-down ")]) : _vm._e(), _vm.showFullList ? _c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-chevron-up")]) : _vm._e()], 1)], 1)]) : _vm._e()], 2), _c('v-list', {
    staticClass: "mx-4",
    attrs: {
      "color": "menuBackground"
    }
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "orange"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("yourWarehouse")) + " "), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm.offerConfig.features.userQuota >= 100 ? [_vm._v(" " + _vm._s(_vm.$t("unlimitedUsers")) + " ")] : [_vm._v(" " + _vm._s(_vm.$tc('users', _vm.offerConfig.features.userQuota, {
    nb: _vm.offerConfig.features.userQuota
  })) + " ")]], 2), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm.offerConfig.features.warehousePartQuota >= 100 ? [_vm._v(" " + _vm._s(_vm.$t("unlimitedParts")) + " ")] : _vm.offerConfig.features.warehousePartQuota ? [_vm._v(" " + _vm._s(_vm.$t("partQuota", {
    nb: _vm.offerConfig.features.warehousePartQuota
  })) + " ")] : _vm._e()], 2), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("fileAnalysis")) + " ")], 1), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("decisionAnalysis")) + " ")], 1), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("materialChoice")) + " ")], 1)], 1), _c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "orange"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("productionOnDemand")) + " "), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("additiveManufacturingService")) + " ")], 1), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("managementProcess")) + " ")], 1), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm.offerConfig.features.availableDeliveryCountries.length > 10 ? [_vm._v(" " + _vm._s(_vm.$t("deliveriesCountries")) + " ")] : [_vm._v(" " + _vm._s(_vm.$t("franceDelivery")) + " ")]], 2), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("trackingDelivery")) + " ")], 1), _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("support")) + " ")], 1)], 1), _c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "orange"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("premiumFeature")) + " "), _vm.offerConfig.features.projectQuota ? _c('v-list-item', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]), _vm.offerConfig.features.projectQuota >= 100 ? [_vm._v(" " + _vm._s(_vm.$t("unlimitedProjects")) + " ")] : [_vm._v(" " + _vm._s(_vm.$tc('projects', _vm.offerConfig.features.projectQuota, {
    nb: _vm.offerConfig.features.projectQuota
  })) + " ")]], 2) : _vm._e(), _vm.offerConfig.features.bulkImport ? _c('v-list-item', {
    staticClass: "white--text"
  }, [_vm.offerConfig.features.bulkImport ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "small": "",
      "color": "error"
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t("bulkImport")) + " ")], 1) : _vm._e(), _c('v-list-item', {
    staticClass: "white--text"
  }, [_vm.offerConfig.features.enableTags ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "small": "",
      "color": "orange"
    }
  }, [_vm._v(" far fa-square ")]), _vm._v(" " + _vm._s(_vm.$t("partTags")) + " ")], 1), _c('v-list-item', {
    staticClass: "white--text"
  }, [_vm.offerConfig.features.sharing.enabled ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "small": "",
      "color": "orange"
    }
  }, [_vm._v(" far fa-square ")]), _vm._v(" " + _vm._s(_vm.$t("partSharing")) + " ")], 1), _c('v-list-item', {
    staticClass: "white--text"
  }, [_vm.offerConfig.features.enableSerialNumber ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "small": "",
      "color": "orange"
    }
  }, [_vm._v(" far fa-square ")]), _vm._v(" " + _vm._s(_vm.$t("serialNumber")) + " ")], 1)], 1)], 1)]], 2), _c('v-col', [_c('v-stepper', {
    staticClass: "mx-2 fill-height mb-12",
    attrs: {
      "flat": "",
      "alt-labels": ""
    },
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-header', {
    staticClass: "mt-6"
  }, [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 1,
      "color": "success",
      "step": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('userInformation')) + " ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 2,
      "color": "success",
      "step": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('companyInformation')) + " ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 3,
      "color": "success",
      "step": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation')) + " ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "color": "success",
      "step": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('signin')) + " ")])], 1), _c('v-stepper-items', {
    staticClass: "col-12 col-md-8 col-xl-10 offset-md-2 offset-xl-1"
  }, [_c('v-stepper-content', {
    staticClass: "mx-auto mt-6",
    attrs: {
      "step": "1"
    }
  }, [_c('h1', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('createBcm')) + " ")]), _c('v-card-text', {
    staticClass: "mt-8"
  }, [_c('v-form', {
    ref: "newUserForm"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('firstname'),
      "rules": _vm.nameRules,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newUser.firstname,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "firstname", $$v);
      },
      expression: "newUser.firstname"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('lastname'),
      "rules": _vm.nameRules,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newUser.lastname,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "lastname", $$v);
      },
      expression: "newUser.lastname"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('email'),
      "rules": _vm.emailRules,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newUser.email,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "email", $$v);
      },
      expression: "newUser.email"
    }
  }), _c('PhoneNumberInput', {
    ref: "phoneNumberInput",
    attrs: {
      "outlined": "",
      "rules": _vm.nameRules,
      "error": _vm.phoneNumberObject && _vm.phoneNumberObject.number.input.trim() !== '' && !_vm.phoneNumberObject.isValid,
      "select-col-classes": "col-4",
      "text-field-col-classes": "col-8"
    },
    on: {
      "validate": _vm.validatePhone
    }
  }), _c('v-select', {
    attrs: {
      "required": "",
      "rules": _vm.nameRules,
      "label": _vm.$t('communicationLanguage'),
      "items": [{
        title: 'English',
        flag: 'gb',
        language: 'EN'
      }, {
        title: 'French',
        flag: 'fr',
        language: 'FR'
      }],
      "item-value": "language",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('flag', {
          staticClass: "mr-4",
          attrs: {
            "iso": item.flag
          }
        }), _vm._v(" " + _vm._s(item.title) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('flag', {
          staticClass: "mr-4",
          attrs: {
            "iso": item.flag
          }
        }), _vm._v(" " + _vm._s(item.title) + " ")];
      }
    }]),
    model: {
      value: _vm.newUser.communicationLanguage,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "communicationLanguage", $$v);
      },
      expression: "newUser.communicationLanguage"
    }
  })], 1), _vm.offerConfig.subscription.price !== 0 ? _c('v-text-field', {
    attrs: {
      "outlined": "",
      "disabled": _vm.discountCode !== '',
      "label": _vm.$t('discountCode'),
      "error": _vm.errorCode
    },
    on: {
      "input": function ($event) {
        _vm.errorCode = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm.discountCode !== '' ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")]) : _vm._e(), _vm.errorCode ? _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" fas fa-times ")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function () {
        return [_vm.discountCode === '' ? _c('v-btn', {
          staticStyle: {
            "margin-top": "-18px"
          },
          attrs: {
            "disabled": _vm.discountCode !== '' || _vm.discountCodeForm === '',
            "loading": _vm.loadingCode,
            "height": "56px",
            "color": "info"
          },
          on: {
            "click": _vm.getDiscountCode
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-check ")]), _vm._v(" Ok ")], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 4037861929),
    model: {
      value: _vm.discountCodeForm,
      callback: function ($$v) {
        _vm.discountCodeForm = $$v;
      },
      expression: "discountCodeForm"
    }
  }) : _vm._e(), _vm.trialPeriod ? _c('v-alert', {
    staticClass: "col-12 text-center",
    attrs: {
      "text": "",
      "outlined": "",
      "type": "info",
      "color": "green"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('trialPeriodOfXDays', _vm.trialPeriod, {
    discountCode: _vm.discountCode,
    trialPeriod: _vm.trialPeriod
  })) + " ")]) : _vm._e(), _c('v-checkbox', {
    staticClass: "pa-0 ma-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('readAndAccept')) + " "), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('a', _vm._g({
                staticClass: "orange--text",
                attrs: {
                  "target": "_blank",
                  "href": "/contracts/cgu.pdf"
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                  }
                }
              }, on), [_vm._v(" CGU ")])];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t('open')) + " CGU ")]), _vm._v(" " + _vm._s(_vm.$t('and')) + " "), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('a', _vm._g({
                staticClass: "orange--text",
                attrs: {
                  "target": "_blank",
                  "href": _vm.$appConfig.customization.login.cgv
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                  }
                }
              }, on), [_vm._v(" CGV ")])];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t('open')) + " CGV ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.checkboxCguCgv,
      callback: function ($$v) {
        _vm.checkboxCguCgv = $$v;
      },
      expression: "checkboxCguCgv"
    }
  }), _c('div', [_vm.offerConfig.subscription.price !== 0 && _vm.offerConfig.subscription.payment_method !== 'MANUAL' ? _c('v-checkbox', {
    attrs: {
      "label": _vm.checkBoxCardLabel,
      "color": "primary"
    },
    model: {
      value: _vm.checkboxAcceptPayment,
      callback: function ($$v) {
        _vm.checkboxAcceptPayment = $$v;
      },
      expression: "checkboxAcceptPayment"
    }
  }) : _vm._e()], 1), _c('v-btn', {
    staticClass: "white--text text-none",
    attrs: {
      "block": "",
      "disabled": !(_vm.checkboxCguCgv && (_vm.checkboxAcceptPayment || _vm.offerConfig.subscription.price === 0 || _vm.offerConfig.subscription.payment_method === 'MANUAL')) || _vm.discountCode === '' && _vm.discountCodeForm !== '',
      "color": "orange"
    },
    on: {
      "click": _vm.nextStep2
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('next')) + " ")])], 1)], 1), _c('v-stepper-content', {
    staticClass: "mx-auto mt-1",
    attrs: {
      "step": "2"
    }
  }, [_c('h1', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('billingAddress')) + " ")]), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('formAddress', {
    ref: "newAddressForm",
    attrs: {
      "address": _vm.newAddress,
      "is-valid-v-a-t": _vm.isValidVAT,
      "restricted-country-list": ((_vm$offerConfig = _vm.offerConfig) === null || _vm$offerConfig === void 0 ? void 0 : (_vm$offerConfig$featu = _vm$offerConfig.features) === null || _vm$offerConfig$featu === void 0 ? void 0 : _vm$offerConfig$featu.theme) === 'DEFAULT' && (_vm$offerConfig2 = _vm.offerConfig) !== null && _vm$offerConfig2 !== void 0 && (_vm$offerConfig2$feat = _vm$offerConfig2.features) !== null && _vm$offerConfig2$feat !== void 0 && _vm$offerConfig2$feat.accessToBCMNetwork ? ['DE', 'AT', 'BE', 'BG', 'CY', 'HR', 'DK', 'ES', 'EE', 'FI', 'FR', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'MT', 'LU', 'NL', 'PL', 'PT', 'CZ', 'RO', 'SK', 'SI', 'SE', 'CH', 'LI', 'AD', 'MC', 'VA', 'SM'] : [],
      "show-first-name": false,
      "show-last-name": false,
      "show-state": false,
      "required-company": true,
      "required-zip-code": true,
      "required-phone-number": true,
      "required-v-a-tnumber": true,
      "allow-to-configure-v-a-t-later": true
    },
    on: {
      "updated": _vm.updateAddress,
      "validateVAT": _vm.validateVAT
    }
  }), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-btn', {
    staticClass: "white--text text-none",
    attrs: {
      "color": "orange",
      "width": "49%"
    },
    on: {
      "click": function ($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('previous')) + " ")]), _c('v-btn', {
    staticClass: "white--text text-none",
    attrs: {
      "color": "success",
      "width": "49%",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.submitRegistration
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('create')) + " ")])], 1)], 1)], 1), _c('v-stepper-content', {
    staticClass: "mx-auto mt-6",
    attrs: {
      "step": "3"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title mt-8 mb-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('emailConfirmation', {
    name: _vm.newUser.firstname
  })) + " ")]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("../../assets/emailSentConfirmation.svg"),
      "alt": "emailSent",
      "max-width": "100"
    }
  })], 1), _c('p', [_vm._v(" " + _vm._s(_vm.$t('messageSendEmail')) + " "), _c('strong', [_vm._v(_vm._s(_vm.newUser.email))])]), _c('p', [_vm._v(_vm._s(_vm.$t('messageClickLink')))]), _c('p', {
    staticClass: "mt-10 mx-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('dontSeeEmail')) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.$t('checkSpam')) + " ")]), _c('p', [_vm._v(_vm._s(_vm.$t('infoSecure')))])], 1)])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }