<template lang="html" src="./pageRegister.template.vue"></template>

<style lang="scss" src="./pageRegister.scss"></style>

<script>
import isEmail from 'validator/lib/isEmail';
import { FormAddress, ApiErrorParser, I18nSelector, PhoneNumberInput } from '@cloudmanufacturingtechnologies/portal-components';
const i18nData = require('./pageRegister.I18n');

export default {
  name: 'PageRegister',
  components: {
    FormAddress,
    ApiErrorParser,
    I18nSelector,
    PhoneNumberInput
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      step: 1,
      loading: false,
      badOffer: false,
      checkboxCguCgv: false,
      checkboxAcceptPayment: false,
      disabled: true,
      offer: null,
      offerConfig: null,
      badeOfferMessage: null,
      newAddress: new this.$BcmModel.Address(),
      newUser: new this.$BcmModel.User(),
      isValidVAT: false,
      discountCodeForm: '',
      discountCode: '', // Need two variables
      reducedOfferPrice: null,
      purchaseOrder: '',
      loadingCode: false,
      errorCode: false,
      nameRules: [(value) => !!value || this.$t('nameRules')],
      emailRules: [
        (value) => !!value || this.$t('emailRequired'),
        (value) => (!value ? false : isEmail(value) || this.$t('emailValid')),
      ],
      trialPeriod: null,
      discounts: [],
      showFullList: false,
      checkBoxCardLabel: '',
      userLocal: this.$userLocal,
      phoneNumberObject: null,
    };
  },
  created() {
    if (this.$route?.params?.offer) {
      this.offer = this.$route.params.offer.toUpperCase();
      this.$apiInstance
        .getWarehouseOffer(this.offer)
        .then((offerConfig) => {
          if(offerConfig.features.theme === this.$appConfig.customization.login._id) {
            this.offerConfig = offerConfig;
            this.checkBoxCardLabel = this.$t(this.offerConfig.subscription.payment_method === 'CARD' ? 'creditCardAccept' : 'transferAccept', {
              offerSelected: this.reducedOfferPrice ? this.reducedOfferPrice : this.offerConfig.subscription.price
            });
          }else{
            this.badOffer = true;
            this.badeOfferMessage = this.$t('defaultErrorMessageOffer');
          }
        })
        .catch(err => {
          this.badOffer = true;
          this.badeOfferMessage = this.$t('defaultErrorMessageOffer');
        });
    }else{
      this.badOffer = true;
      this.badeOfferMessage = this.$t('defaultErrorMessageOffer');
    }
  },
  methods: {
    nextStep2() {
      this.validatePhone(this.$refs.phoneNumberInput.phoneObject);
      if (this.$refs.newUserForm.validate() && this.phoneNumberObject?.isValid) {
        this.newAddress.phoneNumber = this.newUser.phone;
        this.newAddress = Object.assign({}, this.newAddress);
        this.step = 2;
      }
    },
    validatePhone(phoneNumberObject) {
      this.phoneNumberObject = phoneNumberObject;
      this.newUser.phone = phoneNumberObject.isValid ? phoneNumberObject.number.international : phoneNumberObject.number.input;
    },
    submitRegistration() {
      if (
        this.$refs.newAddressForm.validate()
      ) {
        this.loading = true;
        const user = new this.$BcmModel.BrandRegisterUserBody(
          this.newUser.firstname,
          this.newUser.lastname,
          this.newUser.email,
          this.newUser.phone,
          this.newUser.communicationLanguage
        );

        const address = new this.$BcmModel.Address(
          this.newAddress.country,
          this.newAddress.streetAddress,
          this.newAddress.city
        );
        address.company = this.newAddress.company;
        address.phoneNumber = this.newAddress.phoneNumber;
        address.zipCode = this.newAddress.zipCode;
        if(this.newAddress.VATnumber) { // If null, VAT is not added and will be consider to configured later
          address.VATnumber = this.newAddress.VATnumber;
        }

        const addRegisterBody = new this.$BcmModel.BrandRegisterBody(
          this.offer,
          address,
          this.purchaseOrder,
          user
        );

        addRegisterBody.discountCode = this.discountCode;
        this.$apiInstance
          .brandRegister(addRegisterBody)
          .then(
            () => {
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('SUCCESS',this.$t('registerSaved'));
              this.step = 3;
            },
            (error) => {
              /**
               * ERROR ADD BRAND
               */
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    goToLogin() {
      location.href = '/';
    },
    getDiscountCode() {
      this.loadingCode = true;
      this.$apiInstance
        .getDiscountCode(this.discountCodeForm)
        .then((code) => {
          if (
            code.maxUtilization !== -1 &&
            code.maxUtilization - code.totalUtilization <= 0
          ) {
            this.errorCode = true;
            this.$notification.notify('DANGER',this.$t('CodeIsNoLongerUsable'));
          } else if (!code.applicableOffers.includes(this.offer)) {
            this.errorCode = true;
            this.$notification.notify('DANGER',this.$t('CodeIsNotApplicableToOffer', { offer: this.offer }));
          } else {
            this.discountCode = code.code;
            this.discountCodeForm = code.code; // Update with lower/uppercase
            if (code.discounts && Object.keys(code.discounts).length > 0) {
              const keys = Object.keys(code.discounts);
              keys.sort(function(a, b) {
                return parseInt(b) - parseInt(a);
              });

              for (const key of keys) {
                this.discounts.push({
                  discount: parseInt(key),
                  duration: parseInt(code.discounts[key]),
                  reducedOfferPrice: (
                    Math.ceil(
                      100 * (1 - parseInt(key) / 100) * this.offerConfig.subscription.price
                    ) / 100
                  ).toFixed(2),
                });
              }

              this.reducedOfferPrice =
                Math.ceil(
                  100 *
                    (1 - this.discounts[0].discount / 100) *
                    this.offerConfig.subscription.price
                ) / 100;  
            }
            this.checkBoxCardLabel = this.$t(this.offerConfig.subscription.payment_method === 'CARD' ? 'creditCardAccept' : 'transferAccept', {
              offerSelected:
                this.reducedOfferPrice || this.reducedOfferPrice === 0
                  ? this.reducedOfferPrice
                  : this.offerConfig.subscription.price
            });
            if (code.trial_period > 0) {
              this.trialPeriod = parseInt(code.trial_period);
              this.checkBoxCardLabel += this.$t('atTheEndOfTrialPeriod');
            }
            this.$notification.notify('SUCCESS',this.$t('codeRegistered', { discountCode: code.code }));
          
          }
        })
        .catch(() => {
          this.errorCode = true;
          this.$notification.notify('DANGER',this.$t('codeDoesNotExist'));
        })
        .finally(() => {
          this.loadingCode = false;
        });
    },
    updateAddress(address) {
      this.newAddress = address;
    },
    async validateVAT(countryCode, vat) {
      this.isValidVAT = null;
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    }
  },
};
</script>
